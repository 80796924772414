import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { loginOnOk2land, isAuthenticated } from '../helpers/authentication.js';
import Ok2LandLogo from '../resources/images/ok2land.png';

function SignIn() {

    /* Handle login call */
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [isButtonActive, setIsButtonActive] = useState(false);

    // Sign in user
    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await loginOnOk2land(username, password);
            window.location.href = '/movements';
        } catch (err) {
            setError(err.message);
        }
    };

    // Enable button
    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
        updateButtonState(e.target.value, password);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        updateButtonState(username, e.target.value);
    };

    const updateButtonState = (newUsername, newPassword) => {
        setIsButtonActive(newUsername.trim() !== '' && newPassword.trim() !== '');
    };

    /* Render login page layout */
    return (
        <div className='ok2land__login-box'>
            <img className='ok2land__logo' src={Ok2LandLogo} alt='Ok2Land'/>
            <div className='ok2land__login-form'>
                <div className='ok2land__login-form-box'>
                    <form className='login-form' onSubmit={handleLogin}>
                        <div className='login-innerForm'>
                            <span className='ok2land__login-title'>SIGN IN</span>
                            <label>Email</label>
                            <input type="email" id="email" className="mb-20" value={username} onChange={handleUsernameChange} required/>
                            <label>Password</label>
                            <input type="password" id="password" value={password} onChange={handlePasswordChange} required />
                            {error && <p className="error-message">{error}</p>}
                            <span className='ok2land__login-forgot'><Link to='/'>Forgot password?</Link></span>
                            <div className='ok2land__login-form-bottom-box'>
                                <div className='ok2land__login-form-bottom'>
                                    <span className='ok2land__login-signup'>Don’t have an account?<Link to='/welcome'>Register</Link></span>
                                    <button className={`ok2land__btn ${isButtonActive ? 'active-btn' : ''}`} type="submit">SIGN IN</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className='ok2land__login-image-background'>
                <div className='ok2land__login-image-text'>OK2LAND</div>
            </div>
        </div>
    );
}

export default SignIn;